<template>
  <div class="container accountCont">
    <div class="row">
      <div
        col-12
        col-lg-10
        class="align-text-center titleContainer justify-content-center"
      >
        <h1 class="topTitle">Add account</h1>
        <h5 class="subTitle col-6">
          Please select account parameters and check "Debug Info" before
          submitting
        </h5>
      </div>
    </div>
    <hr class="Hline" />
    <div class="logoContainer col-12"><i class="cil-energy"></i></div>
    <div class="rectangle d-flex justify-content-around">
      <b-form @submit="onSubmit" @reset="onReset" v-if="show" class="col-12">
        <b-form-group id="input-group-1" label-for="input-1">
          <div class="row justify-content-around align-items-center selectRow">
            <div
              class="col-3 col-sm-1 newLabel"
              v-bind:class="{ activeText: form.company }"
            >
              COMPANY<br />NAME
            </div>
            <div
              class="new col-7 col-sm-8 prueba"
              v-bind:class="{ active: form.company }"
            >
              <v-select
                :required="!form.company"
                :options="companies"
                label="name"
                v-model="form.company"
                :reduce="(object) => `${object.id}`"
              ></v-select>
            </div>
          </div>
        </b-form-group>

        <b-form-group id="input-group-2" label-for="input-2">
          <div class="row justify-content-around align-items-center selectRow">
            <div
              class="col-3 col-sm-1 newLabel"
              v-bind:class="{ activeText: form.accountTemplate }"
            >
              ACCOUNT<br />TEMPLATE
            </div>
            <div
              class="new col-7 col-sm-8 prueba"
              v-bind:class="{ active: form.accountTemplate }"
            >
              <v-select
                :required="!form.accountTemplate"
                :options="accountTemplates"
                label="name"
                v-model="form.accountTemplate"
                :reduce="(object) => `${object.id}`"
                :filter-by="accountFilter"
              >
                <template #selected-option="{ name, id }">
                  <span>{{ id }} - {{ name }}</span>
                </template>
                <template #option="{ name, id }">
                  <span>{{ id }} - {{ name }}</span>
                </template>
              </v-select>
            </div>
          </div>
        </b-form-group>

        <b-form-group id="input-group-3" label-for="input-3">
          <div class="row justify-content-around align-items-center selectRow">
            <div
              class="col-3 col-sm-1 newLabel"
              v-bind:class="{ activeText: form.currency }"
            >
              CURRENCY
            </div>
            <div
              class="new col-7 col-sm-8 prueba"
              v-bind:class="{ active: form.currency }"
            >
              <v-select
                :required="form.currency"
                :options="currencies"
                label="currency_code"
                :filter-by="currencyFilter"
                v-model="form.currency"
                :reduce="(object) => `${object.id}`"
              >
                <template #selected-option="{ currency_code, description }">
                  <span>{{ currency_code }} - {{ description }}</span>
                </template>
                <template #option="{ currency_code, description }">
                  <span>{{ currency_code }} - {{ description }}</span>
                </template>
              </v-select>
            </div>
          </div>
        </b-form-group>
        <div class="col-12 align-items-center justify-content-center"></div>
        <div class="row">
          <b-button
            squared
            type="submit"
            variant
            class="submit justify-content-center col-3"
            >SUBMIT</b-button
          >
        </div>
        <hr class="Hline" />

        <b-button v-if="false" squared type="reset" variant="danger"
          >Reset</b-button
        >
        <b-alert v-model="showDismissibleAlert" variant="success" dismissible
          >Account created successfully</b-alert
        >
      </b-form>
    </div>

    <div class="row justify-content-end">
      <b-button
        squared
        type="reset"
        class="buttonDebug col-2 justify-content-center"
        @click="showDebug"
        >DEBUG INFO</b-button
      >
    </div>
    <div v-show="this.debug" class="scroll-to-me">
      <b-card class="col-12 d-flex" header="Form Data Result">
        <pre class="m-0">{{ form }}</pre>
      </b-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  getCurrencies,
  getCompanies,
  getAccounts,
} from "../../../services/api.js";
export default {
  name: "accountForm",
  components: {},
  data() {
    return {
      form: {
        company: "",
        accountTemplate: "",
        currency: "",
      },
      debug: false,
      show: true,
      showDismissibleAlert: false,
      //All companies , All curencies , All AcountTemplates
      companies: [],
      currencies: [],
      accountTemplates: [],
      currencyFilter: (option, label, search) => {
        //by default in an array of objects, vue-select will look for a label "key" (only one),the custom filter is used to determine if the provided option matchs the current search text, in order to determine if the option should be displayed.
        let temp = search.toLowerCase();
        return (
          option.description.toLowerCase().indexOf(temp) > -1 ||
          option.currency_code.toLowerCase().indexOf(temp) > -1 ||
          option.id.indexOf(temp) > -1
        );
      },
      accountFilter: (option, label, search) => {
        let temp = search.toLowerCase();
        return (
          option.name.toLowerCase().indexOf(temp) > -1 ||
          option.id.indexOf(temp) > -1
        );
      },
    };
  },
  computed: {
    el() {
      return this.$el.getElementsByClassName("scroll-to-me")[0];
    },
  },

  mounted() {
    this.getCurrencies();
    this.getAccounts();
    this.getCompanies();
  },
  methods: {
    showDebug() {
      if (this.debug == true) {
        this.debug = !this.debug;
      } else if (this.debug == false) {
        (this.debug = !this.debug),
          this.$nextTick(() => {
            this.el.scrollIntoView({ behavior: "smooth" });
          });
      }
    },
    makeToast(variant = null, status, text) {
      this.$bvToast.toast([text], {
        title: `Code: ${status || "default"}`,
        variant: variant,
        solid: true,
        toaster: "b-toaster-top-center",
      });
    },
    getCurrencies() {
      getCurrencies().then(
        (response) => (this.currencies = response.data.data)
      );
    },
    getAccounts() {
      getAccounts().then(
        (response) => (
          console.log(response),
          (this.accountTemplates = response.data.data.accounts)
        )
      );
    },
    getCompanies() {
      getCompanies().then((response) => (this.companies = response.data.data));
    },

    onSubmit(evt) {
      if (
        this.form.company &&
        this.form.accountTemplate &&
        this.form.currency
      ) {
        evt.preventDefault();
        var bodyFormData = new FormData();
        bodyFormData.set("data", this.form);
        axios({
          method: "post",
          url: `https://asd123.free.beeceptor.com/api/v1/companies/${this.form.company}/accounts`,
          data: this.form,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((response) => {
            if (response.status === 200) {
              this.makeToast(
                "success",
                response.status,
                "Account created successfully"
              );
              this.onReset(evt);
            }
          })
          .catch((response) => {
            {
              this.makeToast("danger", "error", "oops! something went wrong");
            }
          });
      } else {
        this.makeToast("danger", "error", "Form incomplete");
      }
    },
    toggleAlert() {
      this.showDismissibleAlert = true;
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.company = "";
      this.form.accountTemplate = "";
      this.form.currency = "";
      // Trick to yet/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
<style scoped>
.rectangle {
  border: 0px solid black;
}
.logoContainer {
  height: 130px;
}
.prueba {
  align-content: baseline;
  align-self: baseline;
  padding: 16px;
  width: 50%;
}

.Hline {
  color: #080707;
  border-top: 2px solid #797979;
  margin-top: 3vh;
}
.selectRow {
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
}
.selectRow:hover {
  background-color: #b0c4de2e;
}
.notSoBigBox {
  background: 199, 196, 205, 0.49;
  margin: 25px 25px;
}
.c-main {
  background-color: #7dc7c4cd !important;
}
.card-header {
  background-color: #d7e4e1d7;
  color: rgba(59, 56, 56, 0.932);
  text-align: center;
  border-bottom: 1px solid #212529;
  padding: 0;
}
.card {
  border: solid 1px black;
  margin-top: 4vh;
  padding: 0;
}
.new {
  border-radius: 1rem;

  background: rgba(26, 72, 100, 0.16);
}
.new:hover {
  border-color: rgb(39, 36, 36);
}
.newLabel {
  font-size: 1.2rem;
  font-weight: 600;
  color: #5c5b5b;
}
.newLabel:hover {
  color: #080707;
}
.titleContainer {
  margin: 0 auto;
}
.topTitle {
  width: 149px;
  height: 33px;
  color: #0291ea;

  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  margin: 0 auto;
}
.subTitle {
  color: #a3a3a3;

  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  margin: 0 auto;
}
.v-select {
  background: #ebebeb;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
}
.activeText {
  font-weight: 700;
  color: #080707;
}
.active {
  background: #0291ea;
}
.submit {
  margin: 0 auto;
  width: 224px;
  height: 75px;
  display: inline-block;
  min-width: 180px;
  background: rgba(2, 145, 234, 0.06);
  box-shadow: 0px 2px 4px rgba(100, 100, 100, 0.5);
  box-shadow: 0px 2px 4px rgba(156, 125, 125, 0.5);
  box-shadow: inset 0px 1px 3px rgba(100, 100, 100, 0.5);

  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  text-align: center;
  color: #080707;
  border: inset 2px #302f2f;
  margin-top: 5vh;
}
.submit:hover {
  border: inset 4px #302f2f;
}
.buttonDebug {
  display: inline-block;
  min-width: 120px;
  border: inset 2px #302f2f;
  border-style: inset;
  background: rgba(255, 255, 255, 0.83);
  text-align: center;
  color: #0e0c0c;

  font-size: 14px;
  font-weight: 600;
  margin-right: 100px;
}
@media (max-width: 600px) {
  form {
    padding-left: 0px;
    padding-right: 0px;
  }
  .buttonDebug {
    margin-right: 20px;
  }
  .prueba {
    padding: 8px;
  }
  .container-fluid {
    padding-right: 0px;
    padding-left: 0px;
  }
}
</style>
